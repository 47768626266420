import React from "react";
import {Button, Col, Row, Space, Switch, TimePicker, Typography} from "antd";
import dayjs from "dayjs";
import {SyncOutlined} from "@ant-design/icons";
import * as lodash from "lodash";
import {IUseHealthCheck, useHealthCheck} from "../hooks/useHealthCheck";
import {BASE_DOMAIN, PROTOCOL} from "../constants";
import {useAutoRefresh} from "../hooks/useAutoRefresh";
import {HealthCheck} from "../components/ui/HealthCheck";

export interface IHealthCheck {
  label: string;
  healthCheck: IUseHealthCheck;
  url?: string;
  group?:
    | "Infrastructure"
    | "Clients"
    | "Services"
    | "Coins Services"
    | "Spot Services"
    | "Utils";
}

export const HealthChecksPage = () => {
  const healthchecks: IHealthCheck[] = [
    // Infrastructure
    {
      label: "Keycloak",
      group: "Infrastructure",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://id.${BASE_DOMAIN}`,
        "text/html"
      ),
    },
    {
      label: "RabbitMQ",
      group: "Infrastructure",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://rabbitmq.${BASE_DOMAIN}`,
        "text/html"
      ),
    },
    {
      label: "Centrifugo",
      group: "Infrastructure",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/centrifugo/`,
        "text/html"
      ),
    },
    // Clients
    {
      label: "Web Client",
      group: "Clients",
      healthCheck: useHealthCheck(`${PROTOCOL}://${BASE_DOMAIN}`, "text/html"),
    },
    {
      label: "Account Client",
      group: "Clients",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://account.${BASE_DOMAIN}`,
        "text/html"
      ),
    },
    {
      label: "WMP Client",
      group: "Clients",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://wmp.${BASE_DOMAIN}`,
        "text/html"
      ),
    },
    // Services
    {
      label: "Config Service",
      group: "Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/config/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "WMP Service",
      group: "Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/wmp/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "Account Service",
      group: "Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/account/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "Notification Service",
      group: "Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/notification/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "Partner Service",
      group: "Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/partner/api/healthcheck`,
        "application/json"
      ),
    },
    /*{
      label: "CMS Service",
      group: "Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/cms/api/healthcheck`,
        "application/json"
      ),
    },*/
    {
      label: "Exchange Service",
      group: "Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/exchange/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "P2P Service",
      group: "Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/p2p/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "Offchain-Exchange Service",
      group: "Services",
      healthCheck: useHealthCheck(
          `${PROTOCOL}://${BASE_DOMAIN}/server/offchain-exchange/api/healthcheck`,
          "application/json"
      ),
    },
    // Coins: bnb, btc, eth, ltc, matic, usdc, usdt
    {
      label: "BNB Coin Service",
      group: "Coins Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/bnb-coin/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "BTC Coin Service",
      group: "Coins Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/btc-coin/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "ETH Coin Service",
      group: "Coins Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/eth-coin/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "LTC Coin Service",
      group: "Coins Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/ltc-coin/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "MATIC Coin Service",
      group: "Coins Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/matic-coin/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "USDC Coin Service",
      group: "Coins Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/usdc-coin/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "USDT Coin Service",
      group: "Coins Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/usdt-coin/api/healthcheck`,
        "application/json"
      ),
    },
    // Spots: bnb/btc, bnb/eht, btc/usdt, eth/btc, eth/usdc, ltc/btc, matic/btc, usdc/usdt
    {
      label: "BNB/BTC Spot Service",
      group: "Spot Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/bnbbtc-spot/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "BNB/ETH Spot Service",
      group: "Spot Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/bnbeth-spot/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "BTC/USDT Spot Service",
      group: "Spot Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/btcusdt-spot/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "ETH/BTC Spot Service",
      group: "Spot Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/ethbtc-spot/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "ETH/USDC Spot Service",
      group: "Spot Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/ethusdc-spot/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "LTC/BTC Spot Service",
      group: "Spot Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/ltcbtc-spot/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "MATIC/BTC Spot Service",
      group: "Spot Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/maticbtc-spot/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "USDC/USDT Spot Service",
      group: "Spot Services",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://${BASE_DOMAIN}/server/usdcusdt-spot/api/healthcheck`,
        "application/json"
      ),
    },
    {
      label: "Traefik",
      group: "Utils",
      url: `${PROTOCOL}://traefik.${BASE_DOMAIN}`,
      healthCheck: useHealthCheck(
        //
        `${PROTOCOL}://${BASE_DOMAIN}`,
        "text/html"
      ),
    },
    {
      label: "Portainer",
      group: "Utils",
      url: `${PROTOCOL}://portainer.${BASE_DOMAIN}`,
      healthCheck: useHealthCheck(
        `${PROTOCOL}://portainer.${BASE_DOMAIN}`,
        "text/html"
      ),
    },
    {
      label: "Zabbix",
      group: "Utils",
      url: `${PROTOCOL}://zabbix.${BASE_DOMAIN}`,
      healthCheck: useHealthCheck(
        `${PROTOCOL}://zabbix.${BASE_DOMAIN}`,
        "text/html"
      ),
    },
    {
      label: "pgAdmin",
      group: "Utils",
      url: `${PROTOCOL}://pgadmin.${BASE_DOMAIN}`,
      healthCheck: useHealthCheck(
        `${PROTOCOL}://pgadmin.${BASE_DOMAIN}/misc/ping`,
        "text/html"
      ),
    },
    {
      label: "Swagger",
      group: "Utils",
      healthCheck: useHealthCheck(
        `${PROTOCOL}://swagger.${BASE_DOMAIN}`,
        "text/html"
      ),
    },
  ];

  const groupedHealthchecks = lodash.groupBy(
    healthchecks,
    (healthcheck) => healthcheck.group
  );

  const autoRefresh = useAutoRefresh(false, 1000 * 5, () =>
    healthchecks.map((item) => item.healthCheck.refresh())
  );

  return (
    <Space direction={"vertical"}>
      <Typography.Title>Status page</Typography.Title>
      <Typography.Paragraph>
        You may disable CORS using this{" "}
        <a
          href={
            "https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf?hl=ru"
          }
          target={"_blank"}
        >
          plugin
        </a>
        .
      </Typography.Paragraph>
      <Space direction={"vertical"}>
        <Space>
          <Typography.Text>Auto refresh</Typography.Text>
          <Switch
            checked={autoRefresh.enabled}
            onChange={(value) => autoRefresh.setEnabled(value)}
          />
          {autoRefresh.enabled && (
            <>
              <Typography.Text>Last update at:</Typography.Text>
              <TimePicker
                bordered={false}
                open={false}
                inputReadOnly={true}
                allowClear={false}
                value={dayjs(autoRefresh.lastRefresh.toISOString())}
                // value={autoRefresh.lastRefresh}
              />
            </>
          )}
        </Space>
        <Space>
          <Typography.Text>Refresh now</Typography.Text>
          <Button
            type={"text"}
            onClick={() => autoRefresh.refresh()}
            icon={<SyncOutlined />}
            disabled={autoRefresh.enabled}
          />
        </Space>
      </Space>
      {Object.keys(groupedHealthchecks).map((group) => {
        const healthchecks = groupedHealthchecks[group];
        return (
          <>
            <Typography.Text strong>{group}</Typography.Text>
            <Row>
              {healthchecks.map((healthcheck, index) => {
                return (
                  <Col
                    key={index}
                    span={24}
                    md={12}
                    lg={8}
                    xl={6}
                    style={{ padding: "0.25%" }}
                  >
                    <HealthCheck {...healthcheck} />
                  </Col>
                );
              })}
            </Row>
          </>
        );
      })}
    </Space>
  );
};
